import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import BackgroundImage from 'gatsby-background-image';
import 'swiper/css/swiper.css';
import styles from './gallery.module.css';

const Gallery = ({ tourGallery }) => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => (
      <div className={`swiper-button-prev ${styles.Buttons}`}></div>
    ),
    renderNextButton: () => (
      <div className={`swiper-button-next ${styles.Buttons}`}></div>
    ),
  };
  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 'auto',
    touchRatio: 0.2,
    slideToClickedSlide: true,
  };
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);
  return (
    <div className={styles.Swiper}>
      <Swiper {...gallerySwiperParams}>
        {tourGallery.map(
          (
            {
              full_image_url: {
                localFile: { childImageSharp: image },
              },
            },
            i
          ) => (
            <BackgroundImage
              key={`${i}_full`}
              Tag={`div`}
              className={styles.GallerItem}
              fluid={image.fluid}
            />
          )
        )}
      </Swiper>
      <Swiper {...thumbnailSwiperParams}>
        {tourGallery.map(
          (
            {
              full_image_url: {
                localFile: { childImageSharp: image },
              },
            },
            i
          ) => (
            <BackgroundImage
              key={`${i}_thumb`}
              Tag={`div`}
              className={styles.GallerItemThumb}
              fluid={image.fluid}
            />
          )
        )}
      </Swiper>
    </div>
  );
};

export default Gallery;
