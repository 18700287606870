import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './timeline.module.css';

const Timeline = ({timeline}) => { 
    const timelineData = [];
    for(let i=1; i<=12;i++){
        if(!timeline[`day_${i}_title`].trim()){
            break;
        }

        timelineData.push({
            title: timeline[`day_${i}_title`],
            description: timeline[`day_${i}_description`]
        }); 
    }
     
    return (
        <div>
            <Row className="mb-3">
                <Col>
                    <h2 className={styles.Title}>Detailed <span>Timeline</span></h2>
                </Col>
            </Row>
            <div className={styles.Timeline}>
                { timelineData.map((timeline,index) => (
                    <Row key={index}>
                        <Col sm={2} xs={12}>
                            <h3 className={styles.Day}>Day {index+1}</h3>
                        </Col>
                        <Col sm={1} xs={1} className="p-0">
                            <div className={styles.Icon}>
                                <i className="icon-location"></i>
                            </div> 
                        </Col>
                        <Col sm={9} xs={12}>
                            <div className={styles.DesSection}>
                                <h4 className={styles.DecTitle}>{timeline.title}</h4>
                                <hr style={{margin:'10px 0'}}/>
                                <p className={styles.Description} dangerouslySetInnerHTML={{__html:timeline.description}}></p>
                            </div>
                            
                        </Col>
                    </Row>
                )) }
            </div> 
        </div>
    );
};

export default Timeline;
