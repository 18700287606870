import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import axios from '../../../axios';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './booking.module.css';

const Booking = ({ tour_id, price, days, minBookNo, jds_type }) => {
  const addDays = useCallback((date, days) => {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }, []);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [totalPrice, setTotalPrice] = useState();
  const [checkInEnabled, setCheckInEnabled] = useState(false);
	const [excludedDates, setExcludedDates] = useState([]);
	const [orderId, setOrderId] = useState();

  const isInValid = () => {
    const adultsCount = adults ? adults : 0;
    const childrenCount = children ? children : 0;

    if (!name.trim()) {
      return true;
    }
    const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.trim() || !emailFormat.test(String(email).toLowerCase())) {
      return true;
    }

    if (!contact.trim()) {
      return true;
    }

    if (adultsCount + childrenCount < 2) {
      return true;
    }
  };

  const bookNow = () => {
    axios
      .post('booking/v1/createorder', {
        formData: JSON.stringify({
          tour_id,
          name,
          email,
          contact,
          checkInDate: checkInDate.toDateString(),
          checkOutDate: checkOutDate.toDateString(),
          adults,
          children,
        }),
      })
      .then(res => {
				const options = JSON.parse(res.data);
				console.log(options)
        options.handler = onPayment;
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      });
  };

  const onPayment = paymentData => {
    axios
      .post('booking/v1/confirmpayment', {
        paymentData: JSON.stringify(paymentData),
      })
      .then(res => {
				const resData = JSON.parse(res.data);
				setOrderId(resData.booking_id);
        resetForm();
      });
  };

  const checkAvailablility = useCallback(() => {
    axios
      .get(
        `booking/v1/checkavailability/${parseInt(adults) + parseInt(children)}`
      )
      .then(res => {
        const excludedDates = JSON.parse(res.data).excludedDates;
        const excludedDatesInstance = excludedDates.map(date => new Date(date));
        setExcludedDates(excludedDatesInstance);
        setCheckInEnabled(true);
      });
  }, [adults, children]);

  const resetForm = () => {
    setName('');
    setEmail('');
    setContact('');
    setCheckInDate();
    setCheckOutDate();
    setAdults(0);
    setChildren(0);
    setCheckInEnabled(false);
    setExcludedDates([]);
  };

  useEffect(() => {
    const adultsCount = !isNaN(adults) ? adults : 0;
    const childrenCount = !isNaN(children) ? children : 0;
    const totalPerson = adultsCount + childrenCount; 
    setCheckInEnabled(false);
		setCheckInDate();
		setCheckOutDate();
    if (totalPerson >= 2) {
      checkAvailablility();
    }
	}, [adults, children, checkAvailablility]);
	

	useEffect(() => {
		const adultsCount = !isNaN(adults) ? adults : 0;
    const childrenCount = !isNaN(children) ? children : 0;
		const totalPerson = adultsCount + childrenCount;
		if(jds_type==='tent' && checkInDate && checkOutDate) {
			const days = Math.ceil((new Date(checkOutDate).getTime() - new Date(checkInDate).getTime()) / (1000*3600*24))+1;
			console.log(days)
			setTotalPrice(totalPerson * days * parseInt(price));
		} else {
			setTotalPrice(totalPerson * parseInt(price));
		}
		
		
	}, [adults, children, checkAvailablility, checkInDate, checkOutDate]);


	useEffect(() => {
		if(jds_type === 'tour' && checkInDate) {
			setCheckOutDate(addDays(new Date(checkInDate), parseInt(days-1)));
		}
	}, [checkInDate, days]);


  return (
    <div className={styles.Booking}>
      <Container className='p-0'>
        <h2 className={styles.BookTitle}>Book Now</h2>
      </Container>
      <Container>
        <Form>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter your name...'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='email'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter your email...'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='telphone'>
            <Form.Label>Contact No.</Form.Label>
            <Form.Control
              type='tel'
              placeholder='Enter your contact no...'
              value={contact}
              onChange={e => setContact(e.target.value)}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group controlId='adults'>
                <Form.Label>Adults</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Adults'
                  min={1}
                  value={adults ? adults : ''}
                  onChange={e => {
                    console.log(e.target.value);
                    if (isNaN(e.target.value) || !e.target.value) {
                      setAdults(0);
                    } else {
                      setAdults(parseInt(e.target.value));
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId='Childrens'>
                <Form.Label>Childrens</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Childrens'
                  min={0}
                  value={children ? children : ''}
                  onChange={e => {
                    if (isNaN(e.target.value) || !e.target.value) {
                      setChildren(0);
                    } else {
                      setChildren(parseInt(e.target.value));
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {adults + children < minBookNo ? (
            <Container>
              <p className='text-center text-warning'>
                Minimum booking required is {minBookNo}
              </p>
            </Container>
          ) : checkInEnabled ? (
            <React.Fragment>
              <Form.Group controlId='check-in'>
                <Form.Label>Check-In Date</Form.Label>
                <div className={styles.DatePicker}>
                  <DatePicker
                    className='form-control'
                    dateFormat='dd/MM/yyyy'
                    minDate={addDays(new Date(), 7)}
                    maxDate={addDays(new Date(), 36)}
                    selected={checkInDate}
                    excludeDates={excludedDates}
                    onChange={date => setCheckInDate(date)}
                  />
                </div>
              </Form.Group>
              {checkInDate ? (
                <Form.Group controlId='check-out'>
                  <Form.Label>Check-Out Date</Form.Label>
                  <div className={styles.DatePicker}>
                    <DatePicker
                      className='form-control'
                      dateFormat='dd/MM/yyyy'
                      minDate={addDays(new Date(checkInDate), 1)}
                      maxDate={addDays(new Date(), 36)}
                      selected={checkOutDate}
                      excludeDates={[...excludedDates, checkInDate]}
											onChange={date => setCheckOutDate(date)}
											disabled={ jds_type==='tour'}
                    />
                  </div>
                </Form.Group>
              ) : null}
            </React.Fragment>
          ) : (
            <p>Fetching available dates...</p>
          )}

          <hr />
          <Row>
            <Col sm={8} className={styles.TotalPrice}>
              Total Price...
            </Col>
            <Col sm={4}> {totalPrice ? `₹ ${totalPrice}` : ''}</Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Button
                variant='primary'
                disabled={isInValid()}
                onClick={bookNow}
                block
              >
                Book Now
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
			<Modal
				show={orderId}
				onHide={() => setOrderId()}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
					  Order Success!
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				   Order ID: {orderId}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setOrderId()}>Close</Button>
				</Modal.Footer>
			</Modal>
    </div>
  );
};

export default Booking;
