import React from 'react';
import styles from './featureicons.module.css';
import featureIcons from '../../../contants/FeatureIconMap';

const FeatureIcons = ({features}) => { 
    return (
        <div className={styles.Features}>
            {
                features.map(feature => (
                    <div key={feature.value} className={styles.FeatureIconBlock}>
                        <div className={styles.FeatureIcon}>
                            { featureIcons[feature.value] }
                        </div>
                        <div className={styles.FeatureLabel}>
                            {feature.label}
                        </div>
                    </div>
                ))
            }
            
        </div>
    );
};

export default FeatureIcons;
