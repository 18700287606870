import React from 'react';
import { graphql } from 'gatsby';
import _ from 'underscore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/Layout';
import Hero from '../components/SingleTour/Hero/Hero';
import FeatureIcons from '../components/SingleTour/FeatureIcons/FeatureIcons';
import Gallery from '../components/SingleTour/Gallery/Gallery';
import Timeline from '../components/SingleTour/Timeline/Timeline';
import Booking from '../components/SingleTour/Booking/Booking';
import styles from './singletour.module.css';

const SetGridRow = ({ child1, child2 }) => {
  return (
    <Row className="mt-3">
      <Col sm={3} xs={12}>
        <h2 className={styles.SectionTitle}>{child1}</h2>
      </Col>
      <Col sm={9} xs={12}>
        {child2}
      </Col>
    </Row>
  );
};

const SingleTour = ({ data: { tour } }) => {
  const {
    wordpress_id: id,
    title,
    content,
    acf: {
      jds_type,
      package_duration,
      package_cost,
      package_place_covered,
      check_in_time,
      check_out_time,
      cancellation_policy,
      advisory,
      minimum_booking,
      things_to_carry,
      package_features,
      inclusion,
      exclusion,
    },
  } = tour;
  const mainImage = tour.featured_media.localFile.childImageSharp.fluid;
  const { tourGallery } = tour;
  return (
    <Layout title={_.unescape(title.replace('&#038;', '&'))}>
      <Hero
        image={mainImage}
        title={title}
        places={package_place_covered}
        price={package_cost}
      />
      <Container className="my-5">
        <Row>
          <Col lg={8} md={12}>
            <FeatureIcons features={package_features} />
            <hr style={{ height: '1px', backgroundColor: '#555' }} />
            <Gallery tourGallery={tourGallery} />
            <SetGridRow
              child1="Description"
              child2={<div dangerouslySetInnerHTML={{ __html: content }} />}
            />
            <SetGridRow child1="Check In" child2={check_in_time} />
            <SetGridRow child1="Check Out" child2={check_out_time} />
            <SetGridRow child1="Duration" child2={`${package_duration} Days`} />
            <SetGridRow
              child1="Things to carry"
              child2={
                <div
                  className={styles.AnyList}
                  dangerouslySetInnerHTML={{ __html: things_to_carry }}
                ></div>
              }
            />
            {inclusion.trim() ? (
              <SetGridRow
                child1="Inclusion"
                child2={
                  <div
                    className={styles.Inclusion}
                    dangerouslySetInnerHTML={{ __html: inclusion }}
                  />
                }
              />
            ) : (
              ''
            )}
            {exclusion.trim() ? (
              <SetGridRow
                child1="Exclusion"
                child2={
                  <div
                    className={styles.Exclusion}
                    dangerouslySetInnerHTML={{ __html: exclusion }}
                  />
                }
              />
            ) : (
              ''
            )}
            <hr style={{ height: '1px', backgroundColor: '#555' }} />
            <Timeline timeline={tour.acf} />
            <hr style={{ height: '1px', backgroundColor: '#555' }} />
            <SetGridRow
              child1="Advisory"
              child2={
                <div
                  className={styles.AnyList}
                  dangerouslySetInnerHTML={{ __html: advisory }}
                ></div>
              }
            />
            <SetGridRow
              child1="Cancellation Policy"
              child2={
                <div
                  className={styles.AnyList}
                  dangerouslySetInnerHTML={{ __html: cancellation_policy }}
                ></div>
              }
            />
          </Col>

          <Col lg={4} md={12} className="mt-md-5 mt-lg-0">
            <Booking
              tour_id={id}
              price={package_cost}
              days={package_duration}
              minBookNo={minimum_booking}
              jds_type={jds_type}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  query Tour($id: Int!) {
    tour: wordpressWpTour(wordpress_id: { eq: $id }) {
      wordpress_id
      title
      slug
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        jds_type
        package_duration
        package_cost
        package_place_covered
        check_in_time
        check_out_time
        cancellation_policy
        advisory
        minimum_booking
        things_to_carry
        package_features {
          value
          label
        }
        day_1_title
        day_1_description
        day_2_title
        day_2_description
        day_3_title
        day_3_description
        day_4_title
        day_4_description
        day_5_title
        day_5_description
        day_6_title
        day_6_description
        day_7_title
        day_7_description
        day_8_title
        day_8_description
        day_9_title
        day_9_description
        day_10_title
        day_10_description
        day_11_title
        day_11_description
        day_12_title
        day_12_description
        inclusion
        exclusion
      }
      tourGallery {
        full_image_url {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1080) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
export default SingleTour;
