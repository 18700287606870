import React from 'react';
import Image from 'gatsby-image'; 
import styles from './hero.module.css';

const Hero = ({image, title, places, price}) => {
    return ( 
        <div className={styles.Hero}>
            <Image fluid={image} 
                   objectFit="cover"
                   objectPosition="50% 50%" 
                   alt={title} 
                   className={styles.HeroImage}/>
            <div className={styles.HeroInfo}>
                <div className={styles.TitlePlace}>
                    <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
                    <p>{places || '--'}</p>
                </div>
                <div className={styles.priceBox}>
                    <span>from/per person</span>
                    <sup>₹</sup>
                    <span className={styles.price}>{price}</span>
                </div>
            </div>
        </div>
    )
}

export default Hero;
